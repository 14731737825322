@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
    min-height: 100vh;
}

#root {
    display: flex;
    flex-direction: column;
}

@font-face {
    font-family: 'Lato';
    src:
        local('Lato'),
        url(./styles/Lato/Lato-Regular.ttf) format('truetype');
    font-display: swap;
}

body {
    margin: 0;
    font-family:
        'Lato',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

[type='text'],
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'],
[multiple],
textarea,
select {
    border-color: white;
    border-width: 1px;
}

.fade-in-out {
    opacity: 0;
    animation:
        fade-in 0.5s ease-in forwards,
        fade-out 0.5s 2.5s ease-out forwards;
}

@keyframes fade-in {
    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
