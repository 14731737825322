.markdown-area ul {
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 2em;
}

.hero-bg {
    opacity: 0.1;
    background-image: url(./components/frontpage/images/pcba-probes.jpg);
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
}

[aria-roledescription='sortable'][aria-pressed='true'] {
    cursor: grabbing;
}
